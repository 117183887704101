import { CredentialPayload, VerifiableCredential } from '@vckit/core-types';
import {
    EPCISEventAction,
    EPCISEventDisposition,
    EPCISEventType,
    IdentificationKeyType,
    LinkResolver,
    LinkType,
    MimeType,
    encryptedService,
    generateUUID,
    integrateVckitIssueVC,
    uploadJson,
} from 'react-component-utils';

import eNVD from '../constants/credentials/eNVD.json';
import { epcisTransactionCrendentialRender } from '../models/epcis';
import { LinkResponse } from '../models/gs1';
import { credentialsIssue } from '../schemas';
import { createLinkResolver } from './createLinkResolver.service';

const registerDLP = async (data: any, credentialPayload: CredentialPayload) => {
    const vc = await issueCredential('DigitalLivestock', data, credentialPayload);
    const NLISID = data['herd']['NLIS'];
    const vcUrl = await uploadVC(`${NLISID}/${generateUUID()}`, vc);
    await registerLinkResolver(vcUrl, IdentificationKeyType.nlisid, NLISID, 'Digital Livestock Passport');
    return vc;
};

const registerConsignmentDLP = async (
    data: any,
    credentialPayload: CredentialPayload,
    transactionEventURL?: string,
) => {
    const vc = await issueCredential('DigitalLivestockConsignment', data, credentialPayload);
    const consignmentNumber = eNVD.consignmentNumber;
    const vcUrl = await uploadVC(`${consignmentNumber}/${generateUUID()}`, vc);
    await registerLinkResolverForConsignmentID(
        consignmentNumber,
        'Digital Livestock Consignment',
        vcUrl,
        transactionEventURL,
    );
    return { vc };
};

export type ENVDData = {
    consignmentNumber?: string;
    livestockIds: string[];
    sender?: { name: string; pic: string };
    receiver?: { name: string; pic: string };
};

const registerENVDVC = async (data: ENVDData, credentialPayload: CredentialPayload) => {
    const eNVDData = {
        consignmentNumber: data.consignmentNumber || eNVD.consignmentNumber,
        livestockIds: data.livestockIds,
        sender: data.sender || eNVD.origin,
        receiver: data.receiver || eNVD.destination,
    };
    const vc = await issueEnvdVC(eNVDData, credentialPayload);
    const eNVDVCUrl = await encryptedService(`${eNVD.consignmentNumber}/${generateUUID()}`, vc);
    const addQueryParamsENVDvcUrl = JSON.stringify({ uri: eNVDVCUrl.uri, hash: eNVDVCUrl.hash });
    const EPCISTransactionEventVC = await issueEPCISTransactionEventVC(
        eNVDData,
        credentialPayload,
        addQueryParamsENVDvcUrl,
    );
    const EPCISVCUrl = await uploadVC(`${eNVD.consignmentNumber}/${generateUUID()}`, EPCISTransactionEventVC);

    await registerEPCISTransactionEventLinkResolver(
        EPCISVCUrl,
        IdentificationKeyType.consignment_id,
        eNVD.consignmentNumber,
        'EPCIS transaction event VC',
    );

    const transactionEventDLR = `${process.env.REACT_APP_DLR_API_URL}/${IdentificationKeyType.consignment_id}/${eNVD.consignmentNumber}?linkType=${LinkType.epcisLinkType}`;

    return { vc, eNVDVCUrl, transactionEventDLR, EPCISVCUrl };
};

/**
 * issue credential for eNVD
 */
const issueEnvdVC = async (entryData: ENVDData, credentialPayload: CredentialPayload) => {
    try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { schema, uischema, description, context, data, ...restOfVC } =
            credentialsIssue['NationalVendorDeclaration'];

        const credentialSubject = updateCredentialSubjectForENVD({ ...eNVD }, entryData);

        const result: VerifiableCredential = await integrateVckitIssueVC({
            context,
            credentialSubject,
            ...credentialPayload,
            restOfVC,
        });

        const credentialValue = result;
        return credentialValue;
    } catch (error) {
        throw new Error('Error issue credential');
    }
};

const updateCredentialSubjectForENVD = (credentialSubject: any, data: ENVDData) => {
    const answersOf10Indexes = (credentialSubject.answers as any[]).reduce(
        (acc: number[], answer: any, idx: number) => {
            if (answer && answer.questionId === '10') {
                acc.push(idx);
            }
            return acc;
        },
        [],
    );

    (credentialSubject.answers as any[]).splice(
        answersOf10Indexes[0],
        answersOf10Indexes.length,
        ...data['livestockIds'].map((livestockId: string, idx: number) => ({
            questionId: '10',
            value: livestockId,
            index: idx,
        })),
    );

    const now = new Date().toISOString();

    credentialSubject.origin = data.sender;
    credentialSubject.destination = data.receiver;
    credentialSubject.createdAt = now;
    credentialSubject.updatedAt = now;
    credentialSubject.submittedAt = now;
    return credentialSubject;
};

/**
 * issue credential for EPCIS Transaction Event
 */
const issueEPCISTransactionEventVC = async (
    data: ENVDData,
    credentialPayload: CredentialPayload,
    eNVDVCUrl: string,
) => {
    try {
        const itemList = data['livestockIds'].map((livestockId: string) => {
            const linkResolver = `${process.env.REACT_APP_DLR_API_URL}/nlisid/${livestockId}?linkType=gs1:certificationInfo`;
            return {
                name: 'Cattle',
                itemID: livestockId,
                link: linkResolver,
            };
        });

        const eventTime = new Date().toUTCString();
        const credentialSubject = {
            sourceParty: { partyID: data.sender!.pic, name: data.sender!.name },
            destinationParty: { partyID: data.receiver!.pic, name: data.receiver!.name },
            transaction: { type: 'inv', identifier: data['consignmentNumber'], documentURL: eNVDVCUrl },
            itemList: itemList,
            readPointId: generateUUID(),
            locationId: 'https://plus.codes/4RRHJ76J+WC',
            eventID: generateUUID(),
            eventTime: eventTime,
            eventType: EPCISEventType.Transaction,
            actionCode: EPCISEventAction.Observe,
            dispositionCode: EPCISEventDisposition.InTransit,
        };

        const restOfVC = { ...epcisTransactionCrendentialRender, type: ['TransactionEventCredential'] };
        const result: VerifiableCredential = await integrateVckitIssueVC({
            context: ['https://dpp-json-ld.s3.ap-southeast-2.amazonaws.com/transaction-event-ld.json'],
            credentialSubject,
            ...credentialPayload,
            restOfVC,
        });

        const credentialValue = result;
        return credentialValue;
    } catch (error) {
        throw new Error('Error issue credential');
    }
};

const registerEPCISTransactionEventLinkResolver = async (
    url: string,
    identificationKeyType: IdentificationKeyType,
    identificationKey: string,
    title: string,
) => {
    const linkResolver: LinkResolver = {
        identificationKeyType,
        identificationKey: identificationKey,
        itemDescription: title,
    };
    const query = encodeURIComponent(JSON.stringify({ payload: { uri: url } }));
    const queryString = `/?q=${query}`;
    const verificationPassportPage = `${process.env.REACT_APP_VERIFICATION_PAGE!}${queryString}`;
    const linkResponses: LinkResponse[] = [
        {
            linkType: LinkType.epcisLinkType,
            linkTitle: title,
            targetUrl: url,
            mimeType: MimeType.applicationJson,
        },
        {
            linkType: LinkType.epcisLinkType,
            linkTitle: title,
            targetUrl: verificationPassportPage,
            mimeType: MimeType.textHtml,
            defaultLinkType: true,
            defaultIanaLanguage: true,
            defaultMimeType: true,
        },
    ];

    await createLinkResolver(linkResolver, linkResponses, queryString);
};

/**
 * issue credential
 */
const issueCredential = async (credentialType: string, entryData: any, credentialPayload: CredentialPayload) => {
    try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { schema, uischema, description, context, data, ...restOfVC } = credentialsIssue[credentialType];

        const result: VerifiableCredential = await integrateVckitIssueVC({
            context,
            credentialSubject: entryData,
            ...credentialPayload,
            restOfVC,
        });

        const credentialValue = result;
        return credentialValue;
    } catch (error) {
        throw new Error('Error issue credential');
    }
};

/**
 * upload VC to S3 after issue credential success and get the passport link
 */
const uploadVC = async (filename: string, vc: VerifiableCredential) => {
    const result = await uploadJson(filename, process.env.REACT_APP_PUBLIC_VC_BUCKET_NAME! as any, vc);
    return result;
};

/**
 * register link resolver to GS1 after getting from uploadVC
 */
const registerLinkResolver = async (
    url: string,
    identificationKeyType: IdentificationKeyType,
    identificationKey: string,
    passportTitle: string,
) => {
    const linkResolver: LinkResolver = {
        identificationKeyType,
        identificationKey: identificationKey,
        itemDescription: passportTitle,
    };
    const query = encodeURIComponent(JSON.stringify({ payload: { uri: url } }));
    const queryString = `/?q=${query}`;
    const verificationPassportPage = `${process.env.REACT_APP_VERIFICATION_PAGE!}${queryString}`;
    const linkResponses: LinkResponse[] = [
        {
            linkType: LinkType.verificationLinkType,
            linkTitle: 'VCKit verify service',
            targetUrl: (process.env.REACT_APP_VERIFICATION_TARGET_URL as string) || '',
            mimeType: MimeType.textPlain,
        },
        {
            linkType: LinkType.certificationLinkType,
            linkTitle: passportTitle,
            targetUrl: url,
            mimeType: MimeType.applicationJson,
        },
        {
            linkType: LinkType.certificationLinkType,
            linkTitle: passportTitle,
            targetUrl: verificationPassportPage,
            mimeType: MimeType.textHtml,
            defaultLinkType: true,
            defaultIanaLanguage: true,
            defaultMimeType: true,
        },
    ];

    await createLinkResolver(linkResolver, linkResponses, queryString);
};

const registerLinkResolverForConsignmentID = async (
    identificationKey: string,
    passportTitle: string,
    passportURL: string,
    transactionEventURL?: string,
) => {
    const linkResolver: LinkResolver = {
        identificationKeyType: IdentificationKeyType.consignment_id,
        identificationKey: identificationKey,
        itemDescription: passportTitle,
    };
    const qualifierPath = '/';

    const linkResponsesForEPCIS: LinkResponse[] = [];
    let queryString = '';
    if (transactionEventURL) {
        const query = encodeURIComponent(JSON.stringify({ payload: { uri: transactionEventURL } }));
        queryString = `?q=${query}`;
        const verificationPassportPage = `${process.env.REACT_APP_VERIFICATION_PAGE!}${queryString}`;
        linkResponsesForEPCIS.push(
            {
                linkType: LinkType.epcisLinkType,
                linkTitle: 'EPCIS transaction event VC',
                targetUrl: transactionEventURL,
                mimeType: MimeType.applicationJson,
            },
            {
                linkType: LinkType.epcisLinkType,
                linkTitle: 'EPCIS transaction event VC',
                targetUrl: verificationPassportPage,
                mimeType: MimeType.textHtml,
                defaultLinkType: true,
                defaultIanaLanguage: true,
                defaultMimeType: true,
            },
        );
    }
    const linkResponses: LinkResponse[] = [
        {
            linkType: LinkType.verificationLinkType,
            linkTitle: 'VCKit verify service',
            targetUrl: (process.env.REACT_APP_VERIFICATION_TARGET_URL as string) || '',
            mimeType: MimeType.textPlain,
        },
        {
            linkType: LinkType.certificationLinkType,
            linkTitle: passportTitle,
            targetUrl: passportURL,
            mimeType: MimeType.applicationJson,
        },
        ...linkResponsesForEPCIS,
    ];

    await createLinkResolver(linkResolver, linkResponses, qualifierPath + queryString);
};

export { registerConsignmentDLP, registerDLP, registerENVDVC };
