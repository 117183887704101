import React, { useEffect } from 'react';
import {
    EPCISEventAction,
    EPCISEventType,
    LinkResolver,
    IdentificationKeyType,
    LinkType,
    MimeType,
    integrateVckitIssueVC,
    createLinkResolver,
    BucketName,
    uploadJson,
    generateUUID,
} from 'react-component-utils';

import { LoadingButton } from '@mui/lab';
import { VerifiableCredential } from '@vckit/core-types';

import LocalStorageService from '../../services/storage.service';
import { epcisObservationCredentialRender } from '../../models/epcis';
import { registerDLP } from '../../services/registerPassport.service';
import { toastError, toastSuccess } from '../../services/toast.service';
import { LinkResponse } from '../../models/gs1';

const FeedCattle = () => {
    const [disabledButton, setDisabledButton] = React.useState<boolean>(true);
    const [loading, setLoading] = React.useState<boolean>(false);
    const issuer = process.env.REACT_APP_LIST_ISSUERS!.split(',')[0];

    useEffect(() => {
        const cattle = LocalStorageService.get('cattleByNLISID');
        const grain = LocalStorageService.get('grains-dpp');

        if (cattle && grain) {
            setDisabledButton(false);
        }
    }, []);

    const feedCattle = async () => {
        setLoading(true);
        try {
            const cattle = JSON.parse(LocalStorageService.get('cattleByNLISID') || '{}');
            const NLISIDs = Object.keys(cattle);
            const grainDPPDLRUrl = LocalStorageService.get('grains-dpp')!;

            const queryForGrainDPP = encodeURIComponent(JSON.stringify({ payload: { uri: grainDPPDLRUrl } }));
            const queryStringForGrainDPP = `/?q=${queryForGrainDPP}`;
            const verificationGrainDPPPage = `${process.env.REACT_APP_VERIFICATION_PAGE!}${queryStringForGrainDPP}`;
            const EPCISObservationEventVC = await issueEPCISObservationEventVC(verificationGrainDPPPage);

            await Promise.all(
                NLISIDs.map(async (NLISID) => {
                    const EPCISVCUrl = await uploadVC(`${NLISID}/${generateUUID()}`, EPCISObservationEventVC);
                    const mockGrainGTIN = '01234567891234';
                    const qualifierPath = `/21/${mockGrainGTIN}`;
                    await registerLinkResolver(
                        EPCISVCUrl,
                        IdentificationKeyType.nlisid,
                        NLISID,
                        qualifierPath,
                        'EPCIS observation event VC',
                    );

                    const epcisDLR = `${process.env.REACT_APP_DLR_API_URL}/${IdentificationKeyType.nlisid}/${NLISID}${qualifierPath}?linkType=${LinkType.epcisLinkType}`;

                    const credentialSubject = { ...cattle[NLISID].credentialSubject };
                    credentialSubject['herd']['traceabilityInfo'].push({
                        EventReference: epcisDLR,
                        EventType: EPCISEventType.Object,
                    });
                    await registerDLP(credentialSubject, { issuer });
                }),
            );
            toastSuccess('Successfully fed the cattle.');
        } catch (error) {
            toastError('Error feeding the cattle.');
        }
        setLoading(false);
    };

    const registerLinkResolver = async (
        url: string,
        identificationKeyType: IdentificationKeyType,
        identificationKey: string,
        qualifierPath: string,
        title: string,
    ) => {
        const linkResolver: LinkResolver = {
            identificationKeyType,
            identificationKey: identificationKey,
            itemDescription: title,
        };
        const query = encodeURIComponent(JSON.stringify({ payload: { uri: url } }));
        const queryString = `/?q=${query}`;
        const verificationPassportPage = `${process.env.REACT_APP_VERIFICATION_PAGE!}${queryString}`;
        const linkResponses: LinkResponse[] = [
            {
                linkType: LinkType.epcisLinkType,
                linkTitle: title,
                targetUrl: url,
                mimeType: MimeType.applicationJson,
            },
            {
                linkType: LinkType.epcisLinkType,
                linkTitle: title,
                targetUrl: verificationPassportPage,
                mimeType: MimeType.textHtml,
                defaultLinkType: true,
                defaultIanaLanguage: true,
                defaultMimeType: true,
            },
        ];

        await createLinkResolver(linkResolver, linkResponses, qualifierPath + queryString);
    };

    /**
     * upload VC to S3
     */
    const uploadVC = async (filename: string, vc: VerifiableCredential) => {
        const result = await uploadJson(filename, BucketName.PublicVC, vc);
        return result;
    };

    /**
     * issue credential for EPCIS Observation Event
     */
    const issueEPCISObservationEventVC = async (grainDPPDLRUrl: string) => {
        try {
            const itemList = [{ name: 'Grain', itemID: '01234567891234', link: grainDPPDLRUrl }];

            const eventTime = new Date().toUTCString();

            const credentialSubject = {
                eventID: generateUUID(),
                eventTime,
                eventType: EPCISEventType.Object,
                actionCode: EPCISEventAction.Observe,
                businessStepCode: 'commissioning',
                dispositionCode: 'dispensed',
                readPointId: generateUUID(),
                locationId: 'https://plus.codes/4RRHJ76J+WC',
                itemList,
                quantityList: [
                    {
                        productClass: 'Grain',
                        quantity: '2',
                        uom: 'Kilogram',
                    },
                ],
            };

            const restOfVC = { ...epcisObservationCredentialRender, type: ['ObjectEventCredential'] };
            const result: VerifiableCredential = await integrateVckitIssueVC({
                context: ['https://dpp-json-ld.s3.ap-southeast-2.amazonaws.com/object-event-ld.json'],
                credentialSubject,
                issuer,
                restOfVC,
            });

            const credentialValue = result;
            return credentialValue;
        } catch (error) {
            throw new Error('Error issue credential');
        }
    };

    return (
        <>
            <LoadingButton loading={loading} variant='contained' disabled={disabledButton} onClick={feedCattle}>
                Feed Cattle
            </LoadingButton>
        </>
    );
};

export default FeedCattle;
