import QRCode from 'qrcode';
import { PDFDocument } from 'pdf-lib';

/**
 * generateQRCode - Generates QR code data url
 * @param qrCodeData
 * @returns URL of QR code
 */
export const generateQRCode = async (qrCodeData: any) => {
    const convertQRDataToString = JSON.stringify(qrCodeData);
    const qrCodeDataURL = await QRCode.toDataURL(convertQRDataToString);
    return qrCodeDataURL;
};

/**
 * addQRCodeToPDF - Adds QR code to PDF
 * @param pdfBase64
 * @param qrCodeData
 * @returns base64 encoded PDF with qr code
 */
export const addQRCodeToPDF = async (pdfBase64: string, qrCodeData: any) => {
    try {
        if (!qrCodeData) {
            throw new Error('No QR code data provided.');
        }

        const qrCodeUrl = await generateQRCode(qrCodeData);

        if (!pdfBase64) {
            throw new Error('No PDF data provided.');
        }

        const pdfDoc = await PDFDocument.load(pdfBase64);
        const pages = pdfDoc.getPages();

        if (!pages.length) {
            throw new Error('No pages found in the PDF.');
        }

        const qrCodeImage = await pdfDoc.embedPng(qrCodeUrl);
        const { width, height } = pages[0].getSize();
        const qrCodeWidth = 50;
        const qrCodeHeight = 50;

        pages[0].drawImage(qrCodeImage, {
            x: width - qrCodeWidth - 100,
            y: height - qrCodeHeight - 5,
            width: qrCodeWidth,
            height: qrCodeHeight,
        });

        const modifiedPdfBytes = await pdfDoc.saveAsBase64({ dataUri: true });
        return modifiedPdfBytes;
    } catch (error: any) {
        throw new Error(error);
    }
};
