import React from 'react';
import IssueFormWrap from '../components/IssueFormWrap/IssueFormWrap';
import { CredentialPayload } from '@vckit/core-types';
import { JsonFormData } from 'react-component-utils';
import { credentialsIssue } from '../schemas';
import { registerConsignmentDLP } from '../services/registerPassport.service';

const formName = 'DigitalLivestockConsignment';
/**
 * DigitalLivestockConsignment component is used to display the schema of DigitalLivestockConsignment.
 */
export const DigitalLivestockConsignment = () => {
    const [dynamicSchema, setDynamicSchema] = React.useState<any>(null);

    React.useEffect(() => {
        if (!dynamicSchema) {
            const schema: any = credentialsIssue[formName].schema;
            setDynamicSchema(schema);
        }
    }, []);

    const processor = async (formData: JsonFormData, credentialPayload: CredentialPayload) => {
        const { vc } = await registerConsignmentDLP(formData, credentialPayload);
        return { vc };
    };

    return (
        <>
            {dynamicSchema && <IssueFormWrap formName={formName} processor={processor} dynamicSchema={dynamicSchema} />}
        </>
    );
};
