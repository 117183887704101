import React from 'react';
import { Button } from '@mui/material';
import { getLinkResolverJsonData, typeOf } from 'react-component-utils';

import DialogScanQrGrainDPP from '../CustomDialog/ScanQrGrainDPP/ScanQrGrainDPP';
import LocalStorageService from '../../services/storage.service';
import { toastError, toastSuccess } from '../../services/toast.service';

const ReceiveGrain = () => {
    const [openDialogScanGrainDPP, setOpenDialogScanGrainDPP] = React.useState<boolean>(false);

    const onScanQRResult = async (value: string) => {
        if (!value) return;
        await handleScanValueAndFetchUri(value);
    };

    const handleScanValueAndFetchUri = async (value: string) => {
        try {
            setOpenDialogScanGrainDPP(false);
            const parsedData = JSON.parse(value);

            if (!parsedData || !parsedData.uri) {
                throw new Error('Invalid QR code uri');
            }
            const data = await getLinkResolverJsonData(parsedData.uri);
            if (typeOf(data) !== 'Object') {
                throw new Error('Invalid type of data');
            }

            LocalStorageService.set('grains-dpp', parsedData.uri);
            toastSuccess('Successfully received the shipment of grain.');
        } catch (error) {
            toastError('Error while receiving the shipment of grain.');
        }
    };

    return (
        <>
            <Button variant='contained' onClick={() => setOpenDialogScanGrainDPP(true)}>
                Receive Grain
            </Button>
            {openDialogScanGrainDPP && (
                <DialogScanQrGrainDPP
                    open={openDialogScanGrainDPP}
                    close={() => setOpenDialogScanGrainDPP(false)}
                    onScanQRResult={onScanQRResult}
                />
            )}
        </>
    );
};

export default ReceiveGrain;
