import { splitStringByDash } from 'react-component-utils';

export function convertStringWithDashToObject(string: string, keys: string[]) {
    const stringArray = splitStringByDash(string);
    const object: any = {};
    keys.forEach((key, index) => {
        object[key] = stringArray[index];
    });
    return object;
}
