import React, { useState } from 'react';
import { decryptString } from '@govtechsg/oa-encryption';
import { computeEntryHash } from '@veramo/utils';
import { getLinkResolverJsonData } from 'react-component-utils';

import DialogScanQrENVD from '../../CustomDialog/ScanQrENVD/ScanQrENVD';
import LocalStorageService from '../../../services/storage.service';
import { toastError, toastSuccess } from '../../../services/toast.service';
import { ConsignmentPIC, handleConsignmentPICs } from '../ImportingLivestock';
import { LoadingButton } from '@mui/lab';

const ReceiveCattle = () => {
    const [openDialogScanQrENVD, setOpenDialogScanQrENVD] = useState(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    const onScanQRResult = (value: string) => {
        if (!value) return;
        handleScanValueAndFetchENVD(value);
    };

    const handleScanValueAndFetchENVD = async (value: string) => {
        try {
            setOpenDialogScanQrENVD(false);
            const parsedData = JSON.parse(value);
            if (!parsedData || !parsedData.uri || !parsedData.hash || !parsedData.key) {
                throw new Error('Invalid QR code');
            }
            const encryptedCredential = await getLinkResolverJsonData(parsedData.uri);

            let stringifyVC;
            try {
                // TODO: using the node-forge to decrypt the encryptedCredential
                stringifyVC = decryptString({
                    ...encryptedCredential,
                    key: parsedData.key,
                    type: 'OPEN-ATTESTATION-TYPE-1',
                });
            } catch (error) {
                console.log({ error });

                toastError('Unable to decrypt the verifiable credential');
                return;
            }

            const vc = JSON.parse(stringifyVC);
            const credentialHash = computeEntryHash(vc);

            if (credentialHash !== parsedData.hash) {
                toastError('Credential hash mismatch detected');
                return;
            }
            setLoading(true);

            const convertToArr = [...[], vc];
            LocalStorageService.set('uploadedENVDs', JSON.stringify(convertToArr));

            const consignmentPICs: ConsignmentPIC[] = [
                {
                    consignmentNumber: vc.credentialSubject.consignmentNumber,
                    PIC: vc.credentialSubject.origin.pic,
                },
            ];
            handleConsignmentPICs(consignmentPICs, () => {
                setLoading(false);
                toastSuccess('Successfully received the shipment of cattle.');
            });
        } catch (error) {
            toastError('Error while receiving the shipment of cattle.');
            return;
        }
    };

    return (
        <>
            <LoadingButton
                loading={loading}
                variant='outlined'
                onClick={() => setOpenDialogScanQrENVD(true)}
                sx={{ margin: '0 5px' }}
            >
                Receive Cattle
            </LoadingButton>
            {openDialogScanQrENVD && (
                <DialogScanQrENVD
                    open={openDialogScanQrENVD}
                    close={() => setOpenDialogScanQrENVD(false)}
                    onScanQRResult={onScanQRResult}
                />
            )}
        </>
    );
};

export default ReceiveCattle;
