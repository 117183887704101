import { IdentificationKeyType } from 'react-component-utils';

export const fetchNLISPIC = async (consignmentNumber: string, PIC: string) => {
    const trustProvenancePICs = (process.env.REACT_APP_TP_PICS?.split(',') || []).map((pic) => {
        const [PICName, PIC] = pic.split('_');
        return {
            PICName,
            PIC,
        };
    });
    const isTrustProvenancePIC = trustProvenancePICs.find((pic) => pic.PIC === PIC);
    const dlr = isTrustProvenancePIC
        ? `${process.env.REACT_APP_TP_LINK_RESOLVER_URL}/${IdentificationKeyType.consignment_id}/${consignmentNumber}?linkType=gs1:epcis`
        : `${process.env.REACT_APP_FARM_LINK_RESOLVER_URL}/${IdentificationKeyType.consignment_id}/${consignmentNumber}?linkType=gs1:epcis`;

    const response = await Promise.resolve([
        {
            Value: {
                Offset: 0,
                Limit: 1,
                Total: 1,
                SubSet: [
                    {
                        PIC: PIC,
                        PICName: isTrustProvenancePIC ? isTrustProvenancePIC.PICName : ' Test PIC',
                        PICRegisterId: 1,
                        BusinessName: '',
                        FirstName: 'JOHN',
                        LastName: 'DOE',
                        Town: 'NORTH SYDNEY',
                        Species: [
                            {
                                ShortCode: 'C',
                            },
                            {
                                ShortCode: 'S',
                            },
                        ],
                        PropertyTypes: [
                            {
                                ShortCode: 'PR',
                            },
                        ],
                        Brands: [
                            {
                                ShortCode: '5UA0',
                            },
                        ],
                        RegisteredState: 'NSW',
                        AdminStatus: {
                            ShortCode: 'A',
                            Description: 'Active',
                        },
                        ShireCode: 'CT',
                        ContactDetails: [
                            {
                                FirstName: 'JOHN',
                                LastName: 'DOE',
                                ContactTypeShortCode: 'PICMANAGERCONTACT',
                            },
                        ],
                        DigitalLinkResolver: dlr,
                    },
                ],
            },
        },
    ]);
    return response;
};

export const eNVDVcMapping = (eNVD: any, cattles: any[]) => {
    const credentialSubject = JSON.parse(JSON.stringify(eNVD));
    const answersOf10Indexes = (credentialSubject.answers as any[]).reduce(
        (acc: number[], answer: any, idx: number) => {
            if (answer && answer.questionId === '10') {
                acc.push(idx);
            }
            return acc;
        },
        [],
    );

    (credentialSubject.answers as any[]).splice(
        answersOf10Indexes[0],
        answersOf10Indexes?.length,
        ...cattles.map((livestockId: any, idx: number) => ({
            questionId: '10',
            value: livestockId.value,
            index: idx,
        })),
    );

    const now = new Date().toISOString();

    credentialSubject.createdAt = now;
    credentialSubject.updatedAt = now;
    credentialSubject.submittedAt = now;
    return credentialSubject;
};

export const DLPConsignmentMapping = (dlp: any, listNLIS: any[]) => {
    const value = JSON.parse(JSON.stringify(dlp));
    value.credentialSubject.herd.NLIS = listNLIS.map((item) => item.value);
    return value.credentialSubject;
};
